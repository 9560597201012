/**
 * Direttiva che crea md-chips per la ricerca di tags
 */

import {IApplicationScope} from "../../shared/global/applicationScope";
import {SenecaResponse} from "atfcore-commonclasses";
import {Tag} from "atfcore-commonclasses";

export interface IfilterChipsDirectiveScope extends IApplicationScope {
    type: string;
    filterPlaceholder: string;
    filteredTags: Array<any>;
    searchTag: Function;
    transformChip: Function;
    addTagToProject: Function;
    addTag: Function;
    removeTagToProject: Function;
    removeTag: Function;
    searchRemoteTagsPromise: any;
    onFocus: Function;
    onBlur: Function;
    foundedTags: any;
}

angular.module('app').directive('filterChips', ($window, $timeout, $translate, TagService, toaster, $rootScope, BgacademyApplicationData, $q) => {
    return {
        restrict: 'AEC',
        scope: {
            type: "@",
            selectedFilters: "=",
            addTagToProject: "<",
            removeTagToProject: "<",
            isScormDisabled: '&',
            onFocus: '&',
            onBlur: '&'
        },
		templateUrl: 'app/shared/filterChips/filterChips.html',
        link: ($scope: IfilterChipsDirectiveScope, element: JQuery, attr: ng.IAttributes) => {
            // Unwrappo le funzioni che aggiungono e rimuovono il tag al progetto, altrimenti non riesco a passare un parametro al controller che chiama la direttiva
            $scope.addTag = $scope.addTagToProject;
            $scope.removeTag = $scope.removeTagToProject;

		angular.element(document).ready(() => {
            let x = element[0].querySelector('input')
            let y = angular.element(x);
		});

            // Lista contenente i tag recuperati con la ricerca
            $scope.filteredTags = [];

            // Imposto i placeholder in base alla tipologia di chip
            if($scope.type == 'softSkill'){
                $scope.filterPlaceholder = $translate.instant("librarySearch.SEARCH_SOFT_SKILL");
            } else if($scope.type == 'techSkill'){
                $scope.filterPlaceholder = $translate.instant("librarySearch.SEARCH_TECH_SKILL");
            } else if($scope.type == 'topic'){
                $scope.filterPlaceholder = $translate.instant("librarySearch.SEARCH_TOPIC");
            } else if($scope.type == 'type'){
                $scope.filterPlaceholder = $translate.instant("librarySearch.SEARCH_TYPE");
            }

            // Effettua la ricerca dei tag in base alla descrizione
            let searchRemoteTags = (tagTitle: string) => {
                return $q((resolve: Function, reject: Function) => {
                // Preparo i dati calcolati da inviare al server
                let fromRecord:number = 0, // numero di record da
                    numRecords:number = 50, // a
                    type:string = null // tipo di tag che sto cercando
                
                // Verifico che tipo di tag sto cercando
                if($scope.type == 'softSkill'){
                    type = BgacademyApplicationData.constants.SOFT_COMPETENCES;
                } else if($scope.type == 'techSkill'){
                    type = BgacademyApplicationData.constants.TECH_COMPETENCES;
                } else if($scope.type == 'topic'){
                    type = BgacademyApplicationData.constants.ARGUMENTS;
                }

                // Eseguo la ricerca, eliminando le eventuali altre pendenti
                if($scope.searchRemoteTagsPromise){
                    $scope.searchRemoteTagsPromise.$cancelRequest();
                }
                $scope.searchRemoteTagsPromise =
                    TagService.findTags.query({
                        fromRecord: fromRecord,
                        numRecords: numRecords,
                        title: tagTitle,
                        type: type
                    });
                $scope.searchRemoteTagsPromise.$promise
                    .then((data: SenecaResponse<Array<Tag>>) => {
                        if(data.error){
                            // Dati non validi, quindi alzo l'errore
                            toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                            reject();
                        } else {
                            if(tagTitle == ""){
                                $scope.foundedTags = data.response;
                            }
                            

                            // Torno i risultati della ricerca
                            resolve(data.response);
                        }
                        // Annullo la promessa
                        $scope.searchRemoteTagsPromise = null;
                    })
                    .catch((error: any) => {
                        // Annullo la promessa
                        $scope.searchRemoteTagsPromise = null;
                        // Non mostro la modale di errore se ho cancellato volutamente la richiesta                            
                        if (!error || error.config.timeout.$$state.status !== 1) {
                            let errors: Array<any> = [];
                            errors.push({ severity: "danger", message: $translate.instant("error.generic.UNKNOWN_ERROR") });
                            $rootScope.$emit("showApplicationModalErrors", errors);
                        }
                        reject();
                    });
                });
            }

            let searchLocaleTags = (searchedText: string) => {
                // Contenitore di tutti i tag (della topologia) locali
                let localTypeTags = BgacademyApplicationData.getItemTypesList();

                // Conenitore degli array filtrati
                let results: Array<any> = [];
                if ((/^\d+$/.test(searchedText))) {
                    results = searchedText ? localTypeTags.filter(
                        (desc: any) => {
                            let regex = new RegExp(searchedText,'gi');
                            return desc.code.match(regex);
                        }
                    ) : localTypeTags;
                } else {
                    results = searchedText ? localTypeTags.filter(
                        (desc: any) => {
                            let lowercaseQuery = angular.lowercase(searchedText);
                            let regex = new RegExp(lowercaseQuery,'gi');
                            return desc.desc.match(regex);
                        }
                    ) : localTypeTags;
                }

                return results;
            };

            $scope.searchTag = (searchedText: string) => {
                let results: any = [];
                if ($scope.type == 'type'){
                    // Le chips della tipologia è un elenco predefinito quindi non serve nessuna chiamata al server
                    results = searchLocaleTags(searchedText);
                } else {
                    // Chiamo il server per recuperare le informazioni
                    results = searchRemoteTags(searchedText);
                }
                return results;
            };

            /* $scope.transformChip = (chip: any) => {
                return chip;
            } */
        }
    };
});